// @flow

import * as React from 'react';
import { useImageLoaded } from '../hooks/useImageLoaded';

type Props = {
  children: React.Node,
};

const testImage = `${window.location.protocol}//${window.location.host}/speedTest.gif`;
const timesToTest = 5;
const worstQuality = 360;
const latencyToQuality = {
  '50': 1080,
  '999': 720,
  '2000': 540
};

export const NetworkSpeedContext = React.createContext({
  quality: worstQuality,
  setQuality: () => { },
});

export const NetworkSpeed = ({ children }: Props) => {
  let [arrTimes, setArrTimes] = React.useState([]);
  let [i, setI] = React.useState(0);
  let [quality, setQuality] = React.useState(worstQuality);
  let [tStart, setTStart] = React.useState(0);

  const {
    ref,
    loaded,
    onLoad,
    setLoaded
  } = useImageLoaded();

  React.useEffect(() => {
    if (i >= timesToTest
      || !ref.current
      || !ref.current.src)
      return;
    if (loaded) {
      setLoaded(false);
      let tEnd = new Date().getTime();
      let tTimeTook = tEnd - tStart;
      arrTimes[i] = tTimeTook;
      setI(i + 1);
      testLatency();
    }
  }, [loaded]);

  const testLatencyCallback = React.useCallback(avg => {
    // determine quality
    const breakpoints = Object.keys(latencyToQuality).map(bp => +bp);
    let quality = worstQuality;
    for (const bp of breakpoints) {
      if (avg <= bp) {
        quality = latencyToQuality[bp];
        break;
      }
    }
    setQuality(quality);
    console.log('Time: ' + (avg.toFixed(2)) + 'ms - quality', quality);
  }, []);

  /** test and average time took to download image from server, called recursively timesToTest times */
  const testLatency = React.useCallback(() => {
    if (!ref.current)
      return;
    const ctime = new Date().getTime();
    setTStart(ctime);
    console.log(i);
    if (i < timesToTest - 1) {
      ref.current.src = testImage + '?t=' + new Date().getTime();
    } else {
      /** calculate average of array items then callback */
      let sum = arrTimes.reduce((a, b) => a + b);
      let avg = sum / arrTimes.length;
      testLatencyCallback(avg);
    }
  }, [i]);

  React.useEffect(() => {
    testLatency();
  }, []);

  return (
    <NetworkSpeedContext.Provider value={{
      quality, setQuality
    }}>
      <img ref={ref} onLoad={onLoad} alt="" style={{
        position: 'absolute',
        left: '-10px',
        top: '-10px'
      }} />
      {children}
    </NetworkSpeedContext.Provider>
  );
};

export const useNetworkSpeed = () => React.useContext(NetworkSpeedContext);
export const useVideoQuality = (maxQuality: ?number) => {
  const { quality } = useNetworkSpeed();
  return {
    quality: maxQuality
      ? Math.min(quality, maxQuality)
      : quality
  };
}