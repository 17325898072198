// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  currentIndex: number,
  slider: any,
  projects: Array<any>,
};

const VideosNumbers = ({
  currentIndex,
  slider,
  projects,
}: Props): React.Node => (
  <div className={css(styles.numbers)}>
    {projects.map((_, index) => (
      <button
        key={index}
        className={css(styles.button)}
        onClick={() => {
          if (slider) {
            slider.current.slickGoTo(index);
          }
        }}
        // onReInit={() => {
        //   slider.current.slickGoTo(1);
        // }}
        style={{
          color: index === currentIndex ? '#000' : '#FFF',
        }}
        type="button"
      >
        {index + 1}
      </button>
    ))}
  </div>
);

const styles = StyleSheet.create({
  button: {
    '-webkit-user-select': 'none',
    '@media screen and (max-width: 414px)': {
      fontSize: '20px',
      padding: 5,
    },
    '@media screen and (min-width: 2000px)': {
      fontSize: '26px',
      lineHeight: '30px',
    },
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '22px',
    margin: 0,
    outline: 'none',
    padding: 10,
    'user-select': 'none',
  },
  numbers: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    right: 10,
    top: 35,
    zIndex: 10,
  },
});

export default VideosNumbers;
