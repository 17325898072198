// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import { AppStateContext } from '../modules';

// Components
import { Cross, TransparentButton } from '.';

const MenuIcon = (): React.Node => (
  <AppStateContext.Consumer>
    {({ page, setPage }) => (
      <TransparentButton
        onClick={() => {
          setPage(page === 'home' ? 'about' : 'home');
        }}
      >
        <section className={css(styles.root)}>
          <div
            className={css(styles.animation)}
            style={{
              transform: `
                rotate(${page === 'home' ? 45 : 0}deg)
            `,
            }}
          >
            <Cross black />
          </div>
        </section>
      </TransparentButton>
    )}
  </AppStateContext.Consumer>
);

const styles = StyleSheet.create({
  animation: {
    transitionDelay: '0.85s',
    transitionDuration: '0.4s',
  },
  root: {
    position: 'fixed',
    right: 10,
    top: 10,
    zIndex: 1000000
  },
});

export default MenuIcon;
