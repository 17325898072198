// @flow

import * as React from 'react';

// Libs
import { useWindowSize } from '@react-hook/window-size';

type Props = {
  children: Array<any>,
  className: any,
  style: any,
};

const FullScreen = ({
  children,
  className,
  style,
}: Props): React.Node => {
  const [width, height] = useWindowSize();

  return (
    <div
      {...{ className }}
      style={{
        height,
        width,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default FullScreen;
