// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

import useMousePosition from '@react-hook/mouse-position';
import { useMouseMove } from '../hooks/useMouseMove';

type Props = {
  children: any,
};

const VideoHideElements = ({ children }: Props): React.Node => {
  const [width, height] = useWindowSize();

  const mousePosition = useMouseMove(2, 'screen');

  return children({ mousePosition });
};

export default VideoHideElements;
