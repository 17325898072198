// @flow

import * as React from 'react';

// Libs
import DocumentTitle from 'react-document-title';

// Modules
import { Request } from '../modules';

// Components
import {
  Footer,
  Loading,
  Logo,
  MenuIcon,
  ScopeDesktop,
  ScopeMobile,
  VideoModalDesktop,
  VideoModalMobile,
} from '../components';

import Videos from '../components/Videos';

const HomeContent = ({ projects }): React.Node => {
  projects.sort((a, b) => a.order - b.order);
  return (
    <section>
      <Videos {...{ projects }} />
      <ScopeDesktop>
        <VideoModalDesktop {...{ projects }} />
      </ScopeDesktop>
      <ScopeMobile>
        <VideoModalMobile {...{ projects }} />
      </ScopeMobile>
    </section>
  );
}

const Home = (): React.Node => (
  <DocumentTitle title="Pinery">
    <section>
      <Request route="projects">
        {({ data: projects }) =>
          projects.length > 0 && (
            <HomeContent {...{ projects }} />
          )
        }
      </Request>
      <MenuIcon />
      <Footer>
        <Logo />
      </Footer>
      {/* <Loading /> */}
    </section>
  </DocumentTitle>
);

export default Home;
