// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

const VideoEmptyLayer = (): React.Node => {
  const [width, height] = useWindowSize();

  return (
    <div
      className={css(styles.root)}
      style={{
        height,
        width,
      }}
    />
  );
};

const styles = StyleSheet.create({
  root: {
    left: 0,
    position: 'absolute',
    top: 0,
    zIndex: 7,
  },
});

export default VideoEmptyLayer;
