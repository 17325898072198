// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

// Modules
import { AppStateContext } from '../modules';

// Components
import { VideoLoopDesktop, VideoTitle } from '.';

type Props = {
  project: any,
};

const VideoPreview = ({ project }: any): React.Node => {
  // eslint-disable-next-line
  const [width, height] = useWindowSize();

  return (
    <div
      className={css(styles.root)}
      style={{
        height,
        width,
      }}
    >
      <VideoLoopDesktop {...{ height, project, width }} />
      <VideoTitle {...project} />
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
  },
});

export default (props: Props): React.Node => (
  <AppStateContext.Consumer>
    {({ page }) => <VideoPreview {...props} {...{ page }} />}
  </AppStateContext.Consumer>
);
