// @flow

import * as React from 'react';

// Libs
import cx from 'classnames';
import { StyleSheet, css } from 'aphrodite';

// Components
import {
  VideoHideElements,
  VideoInfo
} from '..';
import { videoPlayerOptions } from './options';
import { VideoButtonClose } from './VideoButtonClose';

// Hooks
import { useHideControls } from '../../modules';
import { useVideoPlayer } from '../../hooks/useVideoPlayer';
import { useVideoQuality } from '../../modules/NetworkSpeed';

type Props = {
  projects: any[],
  videoIndex: number,
  height: number,
  width: number,
}

const playerOptions = {
  ...videoPlayerOptions,
  autoplay: true,
  muted: false,
};
const noop = () => { };
const PLAYER_CLASS_NAME = 'plyr-desktop';

const VideoPlayerDesktop: React.FC<Props> = ({
  projects,
  videoIndex,
  height,
  width,
}) => {
  const { quality } = useVideoQuality();
  const sources = React.useMemo(() => {
    if (videoIndex < 0
      || !projects[videoIndex])
      return {};

    const project = projects[videoIndex];
    return {
      type: 'video',
      sources: [
        {
          src: project.origin[quality],
          type: 'video/mp4',
          size: +quality
        }
      ],
    }
  }, [projects, videoIndex]);
  const { player } = useVideoPlayer({
    options: playerOptions,
    selector: `.${PLAYER_CLASS_NAME}`,
    sources,
  });
  const { hide } = useHideControls();

  const exitFullscreen = React.useMemo(
    () => player
      ? () => {
        player.fullscreen.exit()
      }
      : noop,
    [player]
  );

  React.useEffect(() => {
    if (player) {
      player.elements.controls.style.opacity = hide ? '0' : '1';
      player.elements.controls.style['pointer-events'] = hide ? 'none' : 'auto';
    }
  }, [hide]);

  return (
    <section className={css(styles.root)}>
      <video
        // playsInline
        className={PLAYER_CLASS_NAME}
        style={{
          width,
          height
        }}
      ></video>
      <VideoHideElements paused>
        <VideoButtonClose exitFullscreen={exitFullscreen} />
        <VideoInfo {...{ projects, videoIndex }} />
      </VideoHideElements>
    </section>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%'
  },
});

export default VideoPlayerDesktop;