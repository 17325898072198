import { includes } from "ramda";

export const videoPlayerOptions = {
  iconUrl: 'plyr_icons.svg',
  fullscreen: {
    enabled: true,
    iosNative: true
  },
  clickToPlay: false,
  // hideControls: false,
  controls: [
    // 'rewind',
    'play',
    // 'fast-forward',
    'progress',
    // 'current-time',
    // 'duration',
    // 'mute',
    // 'volume',
    // 'settings',
    'fullscreen',
  ],
  storage: {
    enabled: false
  },
  i18n: {
    restart: 'Restart',
    rewind: 'Rewind {seektime}s',
    play: 'Play',
    pause: 'Pause',
    fastForward: 'Forward {seektime}s',
    seek: 'Seek',
    seekLabel: '{currentTime} of {duration}',
    played: 'Played',
    buffered: 'Buffered',
    currentTime: 'Current time',
    duration: 'Duration',
    volume: 'Volume',
    mute: 'Mute',
    unmute: 'Unmute',
    enableCaptions: 'Enable captions',
    disableCaptions: 'Disable captions',
    download: 'Download',
    enterFullscreen: 'Enter fullscreen',
    exitFullscreen: 'Exit fullscreen',
    frameTitle: 'Player for {title}',
    captions: 'Captions',
    settings: 'Settings',
    menuBack: 'Go back to previous menu',
    speed: 'Speed',
    normal: 'Normal',
    quality: 'Quality',
    loop: 'Loop',
  }
};

export const isIpad = /iPad|iPod/.test(navigator.platform);
export const getIosVersion = () => {
  let agent = window.navigator.userAgent;
  let start = agent.indexOf('OS');
  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
  }
  return 0;
}
export const iosVersion = getIosVersion();
export const isWeirdIos = isIpad && iosVersion < 12;
export const isIos = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod'
].includes(navigator.platform);
export const isIosInstantFullscreen = ['iPhone', 'iPhone Simulator'].includes(navigator.platform);
export const showModalMobileUI = !isIos || !isIosInstantFullscreen;

export const srcTest = null; // 'https://player.vimeo.com/external/469829546.hd.mp4?s=8a752813d460b7527dcae8070bfa5ff5e2ad675f&profile_id=175';