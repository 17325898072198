// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

import Play from '../assets/icons/play.svg';
import Pause from '../assets/icons/pause.svg';

type Props = {
  onClick: Function,
  paused: boolean,
};

const size = 38;

const VideoPlayButton = ({ onClick, paused }: Props): React.Node => (
  <button
    className={css(styles.root)}
    onClick={() => {
      onClick();
    }}
    type="button"
  >
    <img
      alt="play"
      className={css(styles.img)}
      src={paused ? Play : Pause}
    />
  </button>
);

const styles = StyleSheet.create({
  img: {
    '@media screen and (max-width: 414px)': {
      bottom: 15,
      height: size - 12,
      left: 10,
      width: size - 12,
    },
    '@media screen and (min-width: 2000px)': {
      height: `${size * 1.7 - 2}px`,
      width: `${size * 1.7 - 2}px`,
    },

    height: size - 2,

    width: size - 2,
  },
  root: {
    '-webkit-user-select': 'none',
    backgroundColor: 'transparent',
    border: 'none',
    bottom: 35,
    left: 30,
    outline: 'none',
    position: 'absolute',
    zIndex: 13,
  },
});

export default VideoPlayButton;
