// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

type Props = {
  currentTime: number,
  duration: number,
  paused: boolean,
};

const VideoProgressBarLine = (props: Props): React.Node => {
  const { currentTime, duration, paused } = props;

  const [width] = useWindowSize();

  const componentWidth = width - (width < 2000 ? 250 : 300);

  return (
    <div
      className={css(styles.root)}
      style={{
        transitionDuration: paused ? '0s' : '0.5s',
        width: `${(componentWidth / duration) * currentTime}px`,
      }}
    />
  );
};

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      height: 3,
    },
    '@media screen and (min-width: 2000px)': {
      height: 4,
    },

    backgroundColor: 'white',

    height: 2,
  },
});

export default VideoProgressBarLine;
