// @flow

import * as React from 'react';

import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

// Local
import MousePosition from './MousePosition';
import { useHideControls } from '../modules';

type Props = {
  children: any,
  paused: boolean,
};

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  const reset = (id) => {
    clearInterval(id);
  };

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  React.useEffect(() => {
    const tick = () => {
      // $FlowFixMe
      savedCallback.current();
    };

    const id = setInterval(tick, delay);

    if (delay === 0) {
      reset(id);
    }

    return () => reset(id);
  }, [delay]);
};

const VideoHideElements = ({
  children,
  mousePosition,
  paused,
}: Props & any): React.Node => {
  const [width, height] = useWindowSize();
  const { hide, setHide } = useHideControls();
  const [count, setCount] = React.useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  React.useEffect(() => {
    setCount(0);
    setHide(false);
  }, [mousePosition, paused]);

  React.useEffect(() => {
    if (count === 4) {
      setHide(true);
    }
  }, [count]);

  React.useEffect(() => {
    return () => {
      setHide(false);
    }
  }, []);

  return (
    <div
      className={css(styles.root)}
      style={{
        height,
        opacity: hide ? 0 : 1,
        transition: 'opacity 500ms 0ms',
        width,
      }}
    >
      {children}
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    transition: '0.5s',
  },
});

export default (props: Props): React.Node => {
  return (
    <MousePosition>
      {({ mousePosition }) => (
        <VideoHideElements {...{ ...props, mousePosition }} />
      )}
    </MousePosition>
  );
}
