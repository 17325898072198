// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  children: React.Node,
};

const HorizontalCenter = ({ children }: Props): React.Node => (
  <section className={css(styles.root)}>{children}</section>
);

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default HorizontalCenter;
