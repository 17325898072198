// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

import BlackCrossIcon from '../assets/icons/cross-black.svg';
import WhiteCrossIcon from '../assets/icons/cross-white.svg';

type Props = {
  black?: boolean,
};

const size = 30;

const Cross = ({ black = false }: Props): React.Node => (
  <div className={css(styles.root)}>
    <img
      alt="close"
      className={css(styles.img)}
      src={black ? BlackCrossIcon : WhiteCrossIcon}
    />
  </div>
);

const styles = StyleSheet.create({
  img: {
    '@media screen and (min-width: 2000px)': {
      height: `${size * 1.7}px`,
      width: `${size * 1.7}px`,
    },
    height: size,

    width: size,
  },
  root: {
    padding: 20,
  },
});

export default Cross;
