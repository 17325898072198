// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import { AppStateContext } from '../modules';

// Components
import { TransparentButton } from '.';

// Images
import Black from '../assets/logo/Logo_Pinery_Back_Black_144.png';
import White from '../assets/logo/Logo_Pinery_Front_White_144.png';

type Props = {
  onPress?: Function,
  type?: 'black' | 'white',
};

const Footer = ({ onPress, type = 'white' }: Props): React.Node => (
  <AppStateContext.Consumer>
    {({ page, setPage }) => (
      <TransparentButton
        onClick={() => {
          if (onPress) {
            onPress();
          } else {
            setPage(page === 'home' ? 'about' : 'home');
          }
        }}
      >
        <img
          alt="PINERY"
          className={css(styles.root)}
          src={type === 'black' ? Black : White}
        />
      </TransparentButton>
    )}
  </AppStateContext.Consumer>
);

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      width: '210px',
    },

    '@media screen and (min-width: 2000px)': {
      width: '310px',
    },

    width: '230px',
  },
});

export default Footer;
