// @flow

// Libs
import { StyleSheet, css } from 'aphrodite';

export const aboutStyles = StyleSheet.create({
  root: {
    marginBottom: '20px',
  },
});
