// @flow

import * as React from 'react';

type Props = {
  data: any,
  lang: string,
};

const AboutInfo = ({ data, lang }: Props): React.Node => (
  <div>
    {data[`City_${lang}`]}
    <br />
    {data[`Street_${lang}`]}
    <br />
    {data[`Addition_${lang}`]}
    {data[`Name_${lang}`] && (
      <section>{data[`Name_${lang}`]}</section>
    )}
    {data[`Mail_${lang}`] && (
      <section>
        <a href={`mailto: ${data[`Mail_${lang}`]}‬`}>
          {data[`Mail_${lang}`]}
        </a>
      </section>
    )}
    {data[`Phone_${lang}`] && (
      <section>
        <a href={`tel: ${data[`Phone_${lang}`]}‬`}>
          {data[`Phone_${lang}`]}
        </a>
      </section>
    )}
  </div>
);

export default AboutInfo;
