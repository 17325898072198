// @flow

import * as React from 'react';

// Libs
import ReactDOM from 'react-dom';

// $FlowFixMe
import 'video-react/dist/video-react.css';
// $FlowFixMe
import 'bootstrap/dist/css/bootstrap.css';
// $FlowFixMe
import 'slick-carousel/slick/slick.css';
// $FlowFixMe
import 'slick-carousel/slick/slick-theme.css';

import 'plyr/dist/plyr.css';

import './main.css';
import './css/plyr.css';

// Containers
import { About, Home } from './containers';

// Modules
import { AppState, SliderState, VideoController, HideControls } from './modules';

// Components
import { Flip } from './components';

// Utils
import * as serviceWorker from './serviceWorker';
import { MobileOriginal } from './modules/MobileOriginal';
import { NetworkSpeed } from './modules/NetworkSpeed';

ReactDOM.render(
  <AppState>
    <NetworkSpeed>
      <Flip>
        <VideoController>
          <SliderState>
            <HideControls>
              <MobileOriginal>
                <Home />
              </MobileOriginal>
            </HideControls>
          </SliderState>
        </VideoController>
        <About />
      </Flip>
    </NetworkSpeed>
  </AppState>,
  // $FlowFixMe
  document.getElementById('root'),
);

serviceWorker.unregister();
