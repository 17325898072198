// @flow

import * as React from 'react';

// Libs
import * as R from 'ramda';
import { StyleSheet, css } from 'aphrodite';

// Modules
import { Hover, Request } from '../modules';

// Components
import { AboutInfo } from '.';

// Styles
// import { aboutStyles } from './About.style';

const AboutContactsUa = (): React.Node => (
  <Request route="contacts-ua">
    {({ data }) =>
      R.not(R.isEmpty(data)) && (
        <Hover>
          {({ hover }) => (
            <div className={css(styles.root,)}>
              <AboutInfo {...{ data, lang: hover ? 'Ua' : 'En' }} />
            </div>
          )}
        </Hover>
      )
    }
  </Request>
);

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      fontSize: '20px',
    },
    '@media screen and (min-width: 2000px)': {
      fontSize: '26px',
      lineHeight: '30px',
      marginRight: 0,
    },
    fontFamily: 'UniversLight',

    fontSize: '22px',

    lineHeight: '26px',
  },
});

export default AboutContactsUa;
