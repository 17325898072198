// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import {
  FullScreenContext,
  VideoControllerContext,
} from '../modules';

// Components
import { Cross, TransparentButton } from '.';

const VideoCloseButton = () => (
  <FullScreenContext.Consumer>
    {({ resetFullScreen }) => (
      <VideoControllerContext.Consumer>
        {({ videoIndex, setVideoIndex }) =>
          videoIndex !== -1 && (
            <TransparentButton
              onClick={() => {
                setVideoIndex(-1);
                resetFullScreen();
              }}
            >
              <section className={css(styles.root)}>
                <Cross />
              </section>
            </TransparentButton>
          )
        }
      </VideoControllerContext.Consumer>
    )}
  </FullScreenContext.Consumer>
);

const styles = StyleSheet.create({
  root: {
    position: 'fixed',
    right: 10,
    top: 10,
    zIndex: 1337,
  },
});

export default VideoCloseButton;
