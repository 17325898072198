// @flow

import { useEffect, useLayoutEffect, useState } from 'react';

// Libs
import Plyr from 'plyr';

type UseVideoPlayerOptions = {
  selector: string,
  options: any,
  sources: any
}

export const useVideoPlayer = ({
  selector,
  options,
  sources
}: UseVideoPlayerOptions) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const plyr = new Plyr(
      typeof selector === 'string'
        ? document.querySelector(selector)
        : selector,
      options
    );
    // console.log(plyr);
    // plyr.on('ready', ev => {
    //   if (ev.detail
    //     && ev.detail.plyr) {
    //     const p = ev.detail.plyr;
    //     const sources = p.media.querySelectorAll('source');
    //     for (let i = 0; i < sources.length; ++i) {
    //       sources[i].remove();
    //       p.src = sources.sources[0].src
    //     }
    //   }
    // });
    plyr.source = sources;
    setPlayer(plyr);

    return () => {
      if (plyr
        && 'destroy' in plyr) {
        plyr.destroy();
      }
    }
  }, [selector]);

  return {
    player,
    setPlayer
  };
}

export const useVideoPlayerPlain = ({
  selector,
  options
}: any) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const plyr = new Plyr(
      typeof selector === 'string'
        ? document.querySelector(selector)
        : selector,
      options
    );
    setPlayer(plyr);

    return () => {
      if (plyr
        && 'destroy' in plyr) {
        plyr.destroy();
      }
    }
  }, [selector]);

  return {
    player
  };
}