// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import useMousePosition from '@react-hook/mouse-position';

type Props = {
  componentWidth: number,
  currentTime: number,
  duration: number,
  paused: boolean,
  player: any,
};

const VideoProgressBarCircle = ({
  componentWidth,
  currentTime,
  duration,
  paused,
  player,
}: Props): React.Node => {
  const [mousePosition, ref] = useMousePosition(0, 0, 60);

  const [pressed, setPressed] = React.useState(false);

  const left = pressed
    ? `${mousePosition.x}px`
    : `${(componentWidth / duration) * currentTime}px`;

  const transitionDuration = !paused && !pressed ? '0.5s' : '0s';

  const percentage = (mousePosition.x * 100) / componentWidth;

  const time = (duration * percentage) / 100;

  React.useEffect(() => {
    if (player) {
      if (pressed) {
        player.video.pause();
      } else {
        player.video.play();
      }
    }
  }, [pressed]);

  React.useEffect(() => {
    if (player) {
      if (pressed) {
        player.video.seek(time);
      }
    }
  }, [mousePosition]);

  return (
    <div
      ref={ref}
      className={css(styles.root)}
      onClick={() => {
        player.video.seek(time);
      }}
      onMouseUp={() => {
        setPressed(false);
      }}
      style={{
        height: pressed ? 200 : 30,
        marginTop: pressed ? -99 : -15,
        width: componentWidth,
      }}
    >
      <div
        className={css(styles.circle)}
        onMouseDown={() => {
          setPressed(true);
        }}
        onMouseUp={() => {
          setPressed(false);
        }}
        style={{
          left,
          marginTop: pressed ? 90 : 6,
          transitionDuration,
        }}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  circle: {
    backgroundColor: '#FFF',
    borderRadius: 15,
    cursor: 'pointer',
    height: 15,
    position: 'absolute',
    width: 15,
  },
  root: {
    position: 'absolute',
  },
});

export default VideoProgressBarCircle;
