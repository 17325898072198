// @flow

import * as React from 'react';

// Libs
import * as R from 'ramda';
import { useWheel } from 'react-use-gesture';
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

import Slider from 'react-slick';

// Components
import { VideoPreview, VideosNumbers } from '.';
import { useKeyPress } from '../hooks/useKeyPress';
import { isWeirdIos } from './VideoPlayer/options';
import { useSliderState } from '../modules';

type Props = {
  projects: Array<any>,
};
const SCROLL_DISTANCE_THRESHOLD = 200;

const Videos = ({ projects }: Props): React.Node => {
  const slider = React.useRef<any>(null);
  const { setSlider } = useSliderState();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [width, height] = useWindowSize();

  const handleBeforeChange = React.useCallback((curr, next) => {
    if ((projects.length - 1) === curr) {
      setTimeout(() => {
        slider.current.slickGoTo(0);
      }, 500);
    }
  }, [projects, slider.current]);

  const settings = {
    dots: false,
    infinite: isWeirdIos ? false : true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    accessibility: false
  };

  const leftPressed = useKeyPress('ArrowLeft');
  const rightPressed = useKeyPress('ArrowRight');

  if (leftPressed
    && slider.current) {
    slider.current.slickPrev();
  }
  if (rightPressed
    && slider.current) {
    slider.current.slickNext();
  }

  const bind = useWheel(({ distance, direction }) => {
    const dx = direction[1];
    if (distance >= SCROLL_DISTANCE_THRESHOLD) {
      if (dx < 0) {
        slider.current.slickPrev();
      } else if (dx > 0) {
        slider.current.slickNext();
      }
    }
  });

  const onSliderRef = s => {
    slider.current = s;
    setSlider(s);
  }

  return (
    R.not(R.isEmpty(projects)) && (
      <div {...bind()}>
        <VideosNumbers {...{ currentIndex, projects, slider }} />
        <Slider
          {...settings}
          ref={onSliderRef}
          afterChange={(value) => {
            setCurrentIndex(value);
          }}
          autoplay
          autoplaySpeed={10000}
          className={css(styles.slider)}
          initialSlide={0}
          dots={false}
          style={{
            height,
            width,
          }}
          beforeChange={handleBeforeChange}
        >
          {projects.map((project, index) => (
            <VideoPreview key={index} {...{ index, project }} />
          ))}
        </Slider>
      </div>
    )
  );
};

const styles = StyleSheet.create({
  slider: {
    position: 'absolute',
  },
});

export default Videos;
