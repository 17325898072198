// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { Col, Container, Row } from 'react-bootstrap';
import { useWindowSize } from '@react-hook/window-size';

import DocumentTitle from 'react-document-title';

// Components
import {
  About,
  AboutEn,
  AboutRu,
  AboutUa,
  CloseAbout,
  Footer,
  FullScreen,
  Logo,
  ScopeDesktop,
  ScopeMobile,
} from '../components';

const AboutPage = (): React.Node => {
  const [width, height] = useWindowSize();

  const renderBlocks = () => (
    <Container>
      <Row>
        <Col lg={10}>
          <About />
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <AboutEn />
        </Col>
        <Col lg={4}>
          <AboutRu />
        </Col>
        <Col lg={4}>
          <AboutUa />
        </Col>
      </Row>
      <div className={css(styles.space)} />
    </Container>
  );

  return (
    <DocumentTitle title="Pinery">
      <FullScreen className={css(styles.root)}>
        <CloseAbout />
        <ScopeDesktop>{renderBlocks()}</ScopeDesktop>
        <ScopeMobile>
          <div
            className={css(styles.scroll)}
            style={{
              height,
              width,
            }}
          >
            {renderBlocks()}
          </div>
        </ScopeMobile>
        {/* {(window.vi === undefined || window.vi < 0) && <div>
          <video type="video/mp4" autoPlay muted loop playsInline>
            <source src="https://player.vimeo.com/external/459363633.sd.mp4?s=a0da3fb03b3cc6bc5626aa44e4b477d5c119298f&profile_id=164" type="video/mp4" />
          </video>
        </div>} */}
        <Footer>
          <Logo type="black" />
        </Footer>
      </FullScreen>
    </DocumentTitle>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
  },
  scroll: {
    overflow: 'auto',
    paddingTop: 70,
  },
  space: {
    height: 70,
  },
});

export default AboutPage;
