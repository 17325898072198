// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';
import useMobileDetect from 'use-mobile-detect-hook';

const LoadingScreen = (): React.Node => {
  // TODO: Set `true` before deploy
  const [show, setShow] = React.useState(true);
  const [size, setSize] = React.useState(0);

  const detectMobile = useMobileDetect();

  const [width, height] = useWindowSize();

  React.useEffect(() => {
    setSize(width);

    setTimeout(() => {
      setShow(false);
    }, 7000);
  }, []);

  return (
    show && (
      <div
        className={css(styles.root)}
        style={{
          height,
          width,
        }}
      >
        <pre
          className={css(styles.text, styles.mask)}
          style={{
            background:
              'url(https://ucarecdn.com/29eac20d-1b0e-4775-a324-901cee8841c8/white.png)',
            backgroundSize: detectMobile.isDesktop()
              ? `${size}px 300px`
              : `${height}px ${size}px`,
            transition: 'background-size 28.0s',
          }}
        >
          {detectMobile.isDesktop()
            ? 'NYC КИЇВ МОСКВА'
            : `NYC\nКИЇВ\nМОСКВА`}
        </pre>
      </div>
    )
  );
};

const styles = StyleSheet.create({
  br: {
    height: 1,
  },
  mask: {
    '-moz-background-clip': 'text',
    '-o-background-clip': 'text',
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    backgroundRepeat: 'no-repeat',
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    zIndex: 99999,
  },
  text: {
    '@media screen and (max-width: 1024px)': {
      fontSize: 62,
      lineHeight: '66px',
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: 'center',
    },
    color: 'transparent',
    fontFamily: 'Pinery',
    fontSize: 52,
    textAlign: 'center',
  },
});

export default LoadingScreen;
