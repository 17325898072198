// @flow

import * as React from 'react';

type Props = {
  children: React.Node,
};

export const SliderStateContext = React.createContext({
  slider: null,
  setSlider: () => { },
});

export const SliderState = ({ children }: Props) => {
  let [slider, setSlider] = React.useState<any>(null);

  return (
    <SliderStateContext.Provider value={{
      slider,
      setSlider
    }}>
      {children}
    </SliderStateContext.Provider>
  );
};

export const useSliderState = () => React.useContext(SliderStateContext);