// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  Author: string,
  Brand: string,
  Title: string,
};

const VideoTitle = ({ Author, Brand, Title }: Props): React.Node => (
  <div className={css(styles.root)}>
    <p className={css(styles.title)}>{Brand}</p>
    <p className={css(styles.title)}>{Title}</p>
    <p className={css(styles.title)}>{`by ${Author}`}</p>
  </div>
);

const styles = StyleSheet.create({
  root: {
    '-webkit-transform': 'translate3d(0,0,0)',
    '@media screen and (min-width: 2000px)': {
      top: 120,
    },
    left: 30,
    pointerEvents: 'none',
    position: 'absolute',
    top: 100,
    'user-select': 'none',
    width: '80%',

    'word-break': 'break-word',
  },
  title: {
    '@media screen and (max-width: 414px)': {
      fontSize: '20px',
    },
    '@media screen and (min-width: 2000px)': {
      fontSize: '26px',
      lineHeight: '30px',
    },
    color: '#FFF',
    fontFamily: 'UniversLight',
    fontSize: '22px',

    lineHeight: '26px',

    marginTop: -15,
  },
});

export default VideoTitle;
