// @flow

import * as React from 'react';

export const useImageLoaded = () => {
  const [loaded, setLoaded] = React.useState(false);
  const ref = React.useRef();

  const onLoad = () => {
    setLoaded(true)
  };

  React.useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad()
    }
  });

  return {
    ref,
    loaded,
    setLoaded,
    onLoad
  };
}