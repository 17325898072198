// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import { AppStateContext } from '../modules';

// Modules
import { FullScreen } from '.';

type Props = {
  children: Array<any>,
};

const Flip = ({ children, page }: *): React.Node => {
  const [loaded, setLoaded] = React.useState(false);
  const [deg, setDeg] = React.useState(0);

  React.useEffect(() => {
    if (loaded) {
      setDeg(deg - 180);
    }
  }, [page]);

  React.useEffect(() => {
    setLoaded(true);
  }, [loaded]);

  return (
    <FullScreen className={css(styles.root)}>
      <div
        className={css(styles.flipper)}
        style={{
          transform: `rotateY(${deg}deg)`,
        }}
      >
        <FullScreen className={css(styles.front)}>
          {children[0]}
        </FullScreen>
        <FullScreen className={css(styles.back)}>
          {children[1]}
        </FullScreen>
      </div>
    </FullScreen>
  );
};

export default (props: Props): React.Node => (
  <AppStateContext.Consumer>
    {({ page }) => <Flip {...{ ...props, page }} />}
  </AppStateContext.Consumer>
);

const styles = StyleSheet.create({
  back: {
    backfaceVisibility: 'hidden',
    position: 'absolute',
    transform: 'rotateY(180deg)',
  },
  bg: {
    '@media screen and (max-width: 414px)': {
      display: 'none',
    },
    position: 'absolute',

    zIndex: 0,
  },
  flipper: {
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    position: 'relative',
    transformStyle: 'preserve-3d',
    transition: '0.7s',
    zIndex: 1,
  },
  front: {
    backfaceVisibility: 'hidden',
    position: 'absolute',
    transform: 'rotateY(0deg)',
  },
  root: {
    overflow: 'hidden',
    perspective: '700px',
    position: 'fixed',
  },
});
