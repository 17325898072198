// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  children: React.Node,
};

const FlippedByHorizontal = ({ children }: Props): React.Node => (
  <div className={css(styles.root)}>{children}</div>
);

const styles = StyleSheet.create({
  root: {
    transform: 'rotateY(180deg)',
  },
});

export default FlippedByHorizontal;
