// @flow

import * as React from 'react';

// Libs
import useMobileDetect from 'use-mobile-detect-hook';

type Props = {
  children: any,
};

const ScopeMobile = ({ children }: Props): React.Node => {
  const detectMobile = useMobileDetect();

  return detectMobile.isMobile() && children;
};

export default ScopeMobile;
