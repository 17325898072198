// @flow

import * as React from 'react';

// Libs
import { useWindowSize } from '@react-hook/window-size';
import Plyr from 'plyr';

// Hooks
import { useVideoController } from '../../modules/VideoController';
import { useSliderState } from '../../modules';

// Configs
import { srcTest, isIpad, videoPlayerOptions, isIos, showModalMobileUI } from './options';

type Props = any;

const playerOptions = {
  ...videoPlayerOptions,
  fullscreen: {
    enabled: true,
    iosNative: true,
    container: null
  },
  hideControls: false,
  autoplay: false,
};

const VideoFullMobile: React.FC<Props> = ({
  src,
  height,
  ...props
}) => {
  const [player, setPlayer] = React.useState(null);
  const _video = React.useRef<HTMLVideoElement | null>(null);
  const { setVideoIndex, videoIndex } = useVideoController();
  const { slider } = useSliderState();

  React.useLayoutEffect(() => {
    const p = new Plyr(_video.current, {
      ...playerOptions,
      hideControls: false,
      fullscreen: {
        enabled: true,
        iosNative: true
      },
      ...(showModalMobileUI ? undefined : {
        controls: []
      })
    });
    p.toggleControls(true);
    let interval = -1;

    const onReady = () => {
      const playPromise = p.play();
      if (playPromise) {
        playPromise.catch((err) => {
          console.error('error', err);
          if (isIpad) {
            p.toggleControls(true);
          }
        });
      }
      // return;
      console.log('ready', p.media);
      if (!isIpad) {
        p.media.controls = false;
        p.media.removeAttribute('controls');
        if (p && p.fullscreen) {
          try {
            p.fullscreen.enter();
          } catch (err) { }
          p.media.controls = false;
          p.media.removeAttribute('controls');
        }

        interval = setInterval(() => {
          // console.log('removing', p.media);
          if (p && p.media) {
            p.media.controls = false;
            p.media.removeAttribute('controls');
          }
        }, 2);
      }
      const e = p.play();
      if (e) {
        e.catch((err) => {
          console.error('error', err);
          if (isIpad) {
            p.toggleControls(true);
          }
        });
      }
      if (props.onPlayerReady) {
        props.onPlayerReady(p);
      }
    };

    // _video.current.addEventListener('loadedmetadata', () => onReady());
    p.on('ready', onReady);

    _video.current.src = srcTest || src;

    setPlayer(p);
    // onReady();
    // if (!isIpad) {
    //   if (p && p.fullscreen) {
    //     try {
    //       p.fullscreen.enter()
    //         .catch(console.error);
    //     } catch (err) { }
    //   }
    //   // setTimeout(() => {

    //   // }, 10);
    // }

    return () => {
      clearInterval(interval);
      if (p) {
        p.destroy();
      }
    };
  }, []);

  const cb = React.useCallback(() => {
    if (videoIndex === -1)
      return;
    //check if an element is currently full screen
    console.log('fullscreen was closed');
    player.stop();
    setVideoIndex(-1);
    if (slider) {
      slider.slickPlay();
    }
  }, [slider, player, setVideoIndex, videoIndex]);

  React.useEffect(() => {
    // if (!isIos
    //   || isIpad)
    //   return;
    if (videoIndex < 0
      || !player
      || !player.elements
      || !player.elements.wrapper)
      return;

    const video = player.elements.wrapper.children[0];
    video.addEventListener('webkitendfullscreen', cb, false);
    video.addEventListener('webkitfullscreenchange', cb);
    console.log(player, video);
    return () => {
      video.removeEventListener('webkitendfullscreen', cb, true);
      video.removeEventListener('webkitfullscreenchange', cb);
    }
  }, [videoIndex, player]);

  const [width, hh] = useWindowSize();

  return (
    <video
      width={width}
      height={height}
      // className={cx("mobile-orig-raw", 'hehe')}
      ref={t => _video.current = t}
      preload="metadata"
      loop={props.loop || false}
      playsInline={isIpad ? false : false}
      controls={showModalMobileUI}
    >
    </video>
  );
}

export default VideoFullMobile;
