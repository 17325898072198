// @flow

import * as React from 'react';

// Libs
import Plyr from 'plyr';
import cx from 'classnames';
import { StyleSheet, css } from 'aphrodite';

import { videoPlayerOptions } from './options';
import { useVideoPlayer } from '../../hooks/useVideoPlayer';
import { useEffect } from 'react';
import { useVideoQuality } from '../../modules/NetworkSpeed';

type Props = {
  project: any,
  height: number,
  width: number,
}

const styles = StyleSheet.create({
  root: {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: -1,
  },
});

const playerOptions = {
  ...videoPlayerOptions,
  controls: [],
  muted: true,
  autoplay: true
};

const TYPE = null;

const VideoLoopDesktop: React.FC<Props> = ({
  height,
  width,
  project,
}) => {
  const PLAYER_CLASS_NAME = React.useMemo(() => `plyr-loop-${project.id}`, [project]);
  const { quality } = useVideoQuality(720);
  const desktopLoopSources = React.useMemo(() => {
    if (!project)
      return {};
    return {
      type: 'video',
      sources: [{
        src: project.loop[quality],
        type: 'video/mp4',
        size: +quality
      }]
    };
  }, [project, quality]);

  const { player } = useVideoPlayer({
    options: playerOptions,
    selector: `.${PLAYER_CLASS_NAME}`,
    sources: desktopLoopSources,
  });

  // useEffect(() => {
  //   console.log(player);
  // });

  return (
    <div className={css(styles.root)}
      style={{
        width,
        height
      }}
    >
      <video className={cx(PLAYER_CLASS_NAME, 'plyr')}
        loop
        muted
        // playsInline
        // playsInline
        preload="metadata"
        autoPlay
      >
      </video>
    </div>
  );
}

export default VideoLoopDesktop;