// @flow

import * as React from 'react';

// Libs
import cx from 'classnames';

// Hooks
import { useVideoPlayer } from '../../hooks/useVideoPlayer';
import { useMobileOriginal } from '../../modules/MobileOriginal';
import { useVideoQuality } from '../../modules/NetworkSpeed';

// Configs
import { iosVersion, isIpad, isWeirdIos, videoPlayerOptions } from './options';

type Props = {
  height: number,
  project: any,
  videoIndex: number,
  landscape: any
};

const playerOptions = {
  ...videoPlayerOptions,
  fullscreen: {
    enabled: true,
    fallback: true,
    iosNative: true,
    container: null
  },
  autoplay: isIpad ? false : true,
  muted: true,
  // loop: {
  //   active: true
  // },
  controls: []
};

const VideoPlayerMobile: React.FC<Props> = ({
  height,
  project
}) => {
  const PLAYER_CLASS_NAME = `plyr-mobile-${project.id}`;
  const { quality } = useVideoQuality(720);

  const loopSources = React.useMemo(() => {
    if (!project)
      return {};
    return {
      type: 'video',
      sources: [{
        src: project.loop[quality],
        type: 'video/mp4',
        size: +quality
      }]
    };
  }, [project]);
  const originSources = React.useMemo(() => {
    return {
      type: 'video',
      sources: [{
        src: project.origin[quality],
        type: 'video/mp4',
        size: +quality
      }]
    };
  }, [project]);

  const _video = React.useRef<HTMLVideoElement | null>(null);
  const { player } = useVideoPlayer({
    options: playerOptions,
    selector: isIpad ? null : _video.current || null,
    sources: loopSources
  });
  const {
    player: fullscreenPlayer,
    videoEl: fullscreenVideoEl
  } = useMobileOriginal();

  const onReady = () => {
    player.play();
  }
  React.useEffect(() => {
    if (!player)
      return;
    player.on('ready', onReady);
    return () => {
      player.off('ready', onReady);
    }
  }, [player]);

  const onLinkClick = React.useCallback((e) => {
    try {
      e.preventDefault();

      if (!iosVersion || iosVersion > 11) {
        if (fullscreenPlayer) {
          fullscreenPlayer.play()
            .catch(err => {
              console.error('fs player error', err);
            });
        }
      }
      if (isIpad)
        return;
      setTimeout(() => {
        try {
          if (!isIpad
            && fullscreenPlayer
            && fullscreenPlayer.fullscreen) {
            fullscreenPlayer.fullscreen.enter();
          }
        } catch (e) {
          console.error(e);
        }
      }, 1);
    } catch (err) {
      console.error(err);
    }
    return false;
  }, [originSources, fullscreenVideoEl, _video, fullscreenPlayer, player]);

  return (
    <a href="featured"
      onClick={onLinkClick}
    >
      <div
        style={{
          height,
        }}
      >
        <video
          height={height}
          className={cx('video-raw', {
            [PLAYER_CLASS_NAME]: !isIpad
          })}
          ref={t => _video.current = t}
          preload="metadata"
          autoPlay
          playsInline
          loop
          muted
        >
          <source src={project.loop[quality]}
            type="video/mp4"
          />
        </video>
      </div>
    </a>
  );
}

export default VideoPlayerMobile;
