// @flow

import * as React from 'react';

// Libs
import axios from 'axios';

type Props = {
  children: any,
  route: string,
};

const BASE_URL = 'http://167.172.155.56:1337';

export default ({ children, route }: Props): React.Node => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await axios
        .get(`${BASE_URL}/${route}/`)
        .catch((error) => {
          console.log(error);
        });

      setData(res.data);
    })();
  }, []);

  return children({ data });
};
