// @flow

import * as React from 'react';

// Libs
import { useWindowSize } from '@react-hook/window-size';
import { StyleSheet, css } from 'aphrodite';

// Modules
import {
  FullScreenContext,
  VideoControllerContext,
} from '../modules';

// Components
import { VideoPlayerDesktop } from '.';

type Props = {
  projects: Array<any>,
};

const VideoModalDesktop = ({ projects }: Props): React.Node => {
  const [width, height] = useWindowSize();

  return (
    <VideoControllerContext.Consumer>
      {({ videoIndex }) => {
        const show = videoIndex !== -1;

        return (
          show && (
            <div
              className={css(styles.root)}
              style={{
                height,
                left: show ? 0 : -height * 2,
                top: show ? 0 : -width * 2,
                width,
              }}
            >
              <VideoPlayerDesktop
                {...{ height, projects, videoIndex, width }}
              />
            </div>
          )
        );
      }}
    </VideoControllerContext.Consumer>
  );
};

export default (props: Props): React.Node => (
  <FullScreenContext.Consumer>
    {({
      enterFullScreen,
      fullScreen,
      fullScreenProcessing,
      resetFullScreen,
    }) => (
      <VideoControllerContext.Consumer>
        {({ videoIndex, setVideoIndex }) =>
          !fullScreenProcessing && (
            <VideoModalDesktop
              {...{
                ...props,
                enterFullScreen,
                fullScreen,
                resetFullScreen,
                setVideoIndex,
                videoIndex,
              }}
            />
          )
        }
      </VideoControllerContext.Consumer>
    )}
  </FullScreenContext.Consumer>
);

const styles = StyleSheet.create({
  root: {
    '-webkit-user-select': 'none',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 13,
  },
});
