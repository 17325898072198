// @flow

import * as React from 'react';

type Props = {
  children: any,
};

export const VideoControllerContext =
  // $FlowFixMe
  React.createContext({
    setVideoIndex: () => { },
    videoIndex: -1,
    hideControls: false,
    setHideControls: () => { },
  });

export const useVideoController = () => React.useContext(VideoControllerContext);

const VideoController = ({ children }: Props) => {
  const [
    videoIndex: number,
    setVideoIndex: Function,
  ] = React.useState(-1);
  const [
    hideControls: boolean,
    setHideControls: Function
  ] = React.useState(false);

  return (
    <VideoControllerContext.Provider
      value={{
        setVideoIndex: (v) => {
          setVideoIndex(v);
          window.vi = v;
        },
        videoIndex,
        setHideControls,
        hideControls
      }}
    >
      {children}
    </VideoControllerContext.Provider>
  );
};

export default VideoController;
