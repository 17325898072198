// @flow

import * as React from 'react';

// Libs
import useMobileDetect from 'use-mobile-detect-hook';

type Props = {
  children: any,
};

const ScopeDesktop = ({ children }: Props): React.Node => {
  const detectMobile = useMobileDetect();

  return detectMobile.isDesktop() && children;
};

export default ScopeDesktop;
