// @flow

import * as React from 'react';

// Libs
import * as R from 'ramda';
import { StyleSheet, css } from 'aphrodite';
import useMousePosition from '@react-hook/mouse-position';

// Modules
import { VideoControllerContext } from '../modules';

// Components
import {
  FullScreen,
  ScopeDesktop,
  ScopeMobile,
  VideoPreviewDesktop,
  VideoPreviewMobile,
} from '.';

type Props = {
  index: number,
  project: any,
};

const VideoPreview = ({ index, project }: Props): React.Node => {
  const [mousePosition, ref] = useMousePosition(0, 0, 60);
  const [position, setPosition] = React.useState({});

  return (
    <VideoControllerContext.Consumer>
      {({ setVideoIndex }) => (
        <button
          ref={ref}
          className={css(styles.root)}
          onMouseDown={() => {
            setPosition(mousePosition);
          }}
          onMouseUp={(e) => {
            if (
              R.equals(
                R.props(['pageX', 'pageY'], mousePosition),
                R.props(['pageX', 'pageY'], position),
              )
            ) {
              console.log('on mouse up');
              e.stopPropagation();
              setVideoIndex(index);
            }
          }}
          type="button"
        >
          <ScopeDesktop>
            <VideoPreviewDesktop {...{ index, project }} />
          </ScopeDesktop>
          <ScopeMobile>
            {/* <FullScreen> */}
              <VideoPreviewMobile {...{ project }} videoIndex={index} />
            {/* </FullScreen> */}
          </ScopeMobile>
        </button>
      )}
    </VideoControllerContext.Consumer>
  );
};

const styles = StyleSheet.create({
  root: {
    '-webkit-user-select': 'none',
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0,
    outline: 'none',
    padding: 0,
    'user-select': 'none',
  },
});

export default VideoPreview;
