// @flow

import * as React from 'react';

// Libs
import * as R from 'ramda';
import { StyleSheet, css } from 'aphrodite';

// Modules
import { Request } from '../modules';

const AboutContactsEn = (): React.Node => (
  <Request route="about">
    {({ data }) =>
      R.not(R.isEmpty(data)) && (
        <p
          className={css(styles.root)}
          dangerouslySetInnerHTML={{ __html: data.Description }}
        />
      )
    }
  </Request>
);

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      fontSize: '20px',
      marginBottom: '20px',
    },
    '@media screen and (min-width: 2000px)': {
      fontSize: '26px',
      lineHeight: '30px',
    },
    fontFamily: 'UniversLight',
    fontSize: '22px',
    lineHeight: '26px',

    marginBottom: 50,

    marginTop: 50,
  },
});

export default AboutContactsEn;
