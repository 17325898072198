// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import { VideoControllerContext } from '../modules';

type Props = {
  children: any,
};

const VideosFadeOut = ({ children }: Props): React.Node => (
  <VideoControllerContext.Consumer>
    {({ videoIndex }) => (
      <div
        className={css(styles.root)}
        style={{
          opacity: videoIndex === -1 ? 1 : 0,
        }}
      >
        {children}
      </div>
    )}
  </VideoControllerContext.Consumer>
);

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      transitionDuration: '0s',
    },

    transitionDuration: '0.2s',
  },
});

export default VideosFadeOut;
