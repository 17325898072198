// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

// Components
import { VideoLoopMobile, VideoTitle } from '.';

type Props = {
  videoIndex: number,
  project: any,
};

const VideoPreview = ({ project, videoIndex }: Props): React.Node => {
  const [width, height] = useWindowSize();

  return (
    <div
      className={css(styles.root)}
      style={{
        height,
        width,
      }}
    >
      <VideoLoopMobile {...{ height, videoIndex, project }} />
      <VideoTitle {...project} />
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
  },
});

export default VideoPreview;
