// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  children: any,
};

const FadeIn = ({ children }: Props): React.Node => (
  <div className={css(styles.root)}>{children}</div>
);

const opacityKeyframes = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

const styles = StyleSheet.create({
  root: {
    animationDuration: `0.7s, 700ms`,
    animationName: [opacityKeyframes],
    transitionDuration: '0.4s',
  },
});

export default FadeIn;
