// @flow

import * as React from 'react';

// Libs
import * as R from 'ramda';
import { StyleSheet, css } from 'aphrodite';

// Modules
import { Request } from '../modules';

// Styles
import { aboutStyles } from './About.style';

const AboutContactsEn = (): React.Node => (
  <Request route="contacts-en">
    {({ data }) =>
      R.not(R.isEmpty(data)) && (
        <div className={css(styles.root, aboutStyles.root)}>
          {data.City}
          <br />
          {data.Street}
          <br />
          {data.Addition}
          {data.Name && (
            <>
              <br />
              {data.Name}
            </>
          )}
          {data.Mail && (
            <>
              <br />
              <a href={`mailto: ${data.Mail}‬`}>{data.Mail}</a>
            </>
          )}
          {data.Phone && (
            <>
              <br />
              <a href={`tel: ${data.Phone}‬`}>{data.Phone}</a>
            </>
          )}
        </div>
      )
    }
  </Request>
);

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      fontSize: '20px',
    },
    '@media screen and (min-width: 2000px)': {
      fontSize: '26px',
      lineHeight: '30px',
      marginRight: 0,
    },
    fontFamily: 'UniversLight',

    fontSize: '22px',

    lineHeight: '26px',
  },
});

export default AboutContactsEn;
