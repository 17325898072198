// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  children: React.Node,
  onClick: Function,
  onMouseDown?: Function,
  onMouseUp?: Function,
};

const noop = () => {};

const TransparentButton = ({
  children,
  onClick,
  onMouseDown = noop,
  onMouseUp = noop,
}: Props): React.Node => (
  <button
    className={css(styles.root)}
    onClick={() => {
      onClick();
    }}
    onMouseDown={() => {
      onMouseDown();
    }}
    onMouseUp={() => {
      onMouseUp();
    }}
    type="button"
  >
    {children}
  </button>
);

const styles = StyleSheet.create({
  root: {
    '-webkit-user-select': 'none',
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0,
    outline: 'none',
    padding: 0,
    'user-select': 'none',
  },
});

export default TransparentButton;
