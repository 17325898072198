// @flow

import * as React from 'react';

// Libs
import { useWindowSize } from '@react-hook/window-size';

// Components
import VideoPlayerMobile from './VideoPlayerMobile';

type Props = {
  project: any,
  videoIndex: number,
}

const VideoLoopMobile: React.FC<Props> = ({
  project,
  videoIndex,
}) => {
  const [width, height] = useWindowSize();

  const part = height / 16;

  const itemWidth = part * 9;

  const ratio = height / 720;

  const resizedImageWidth = 1280 * ratio;

  const landscape = width > height;

  const cityHeights = []; 

  return (
    <div
      className="mobile-loop-wrap"
      style={{
        // marginLeft: landscape
        //   ? 0
        //   // : -(resizedImageWidth - itemWidth) / 2,
        //   : -(itemWidth) / 1.5
      }}
    >
      <VideoPlayerMobile {...{ height, landscape, videoIndex, project }} />
    </div>
  );
}

export default VideoLoopMobile;