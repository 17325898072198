// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

type Props = {
  videoIndex: number,
  projects: Array<any>,
};

const VideoInfo = ({ videoIndex, projects }: Props): React.Node => (
  <div className={css(styles.root)}>
    <p className={css(styles.text)}>
      {videoIndex !== -1 ? projects[videoIndex].Description : ''}
    </p>
  </div>
);

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      paddingLeft: 40,
      paddingRight: 40,
      top: 80,
    },
    left: 0,
    position: 'absolute',
    top: 40,
    width: '100%',
  },
  text: {
    '-webkit-user-select': 'none',
    '@media screen and (max-width: 414px)': {
      fontSize: '20px',
    },
    '@media screen and (min-width: 2000px)': {
      fontSize: '26px',
      lineHeight: '30px',
    },
    color: '#FFF',
    fontFamily: 'UniversLight',
    fontSize: '22px',
    textAlign: 'center',
    'user-select': 'none',
  },
});

export default VideoInfo;
