// @flow

import * as React from 'react';

type Props = {
  children: React.Node,
};

export const AppStateContext =
  // $FlowFixMe
  React.createContext({
    page: [],
    setPage: () => {},
  });

const AppState = ({ children }: Props) => {
  const [page: 'about' | 'home', setPage: Function] = React.useState(
    'home',
  );

  return (
    <AppStateContext.Provider value={{ page, setPage }}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppState;
