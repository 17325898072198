// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';
import { useWindowSize } from '@react-hook/window-size';

// Components
import Circle from './Video.ProgressBar.Circle';
import Line from './Video.ProgressBar.Line';
import Time from './Video.ProgressBar.Time';

type Props = {
  currentTime: number,
  duration: number,
  paused: boolean,
  player: any,
};

const VideoProgressBar = (props: Props): React.Node => {
  const [width] = useWindowSize();

  const componentWidth = width - (width < 2000 ? 250 : 300);

  return (
    <div
      className={css(styles.root)}
      style={{
        width: `${componentWidth}px`,
      }}
    >
      <div
        className={css(styles.wrapper)}
        style={{
          width: `${componentWidth}px`,
        }}
      >
        <Line {...props} />
        <Circle {...{ ...props, componentWidth }} />
      </div>
      <Time {...props} />
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    '@media screen and (max-width: 414px)': {
      bottom: 50,
    },
    '@media screen and (min-width: 2000px)': {
      bottom: 65,
      left: 120,
    },
    bottom: 52,
    left: 100,

    position: 'absolute',

    zIndex: 13,
  },
  wrapper: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    cursor: 'pointer',
    height: 2,
    marginLeft: 50,
  },
});

export default VideoProgressBar;
