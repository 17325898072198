// @flow

import * as React from 'react';

// Libs
import { useWindowSize } from '@react-hook/window-size';
import { StyleSheet, css } from 'aphrodite';

// Modules
import { VideoControllerContext } from '../modules';

// Component
import { Cross, Footer, Logo, TransparentButton, VideoFullMobile } from '.';
import { useMobileOriginal } from '../modules/MobileOriginal';
import { srcTest, isIpad, videoPlayerOptions, isIosInstantFullscreen, isIos, showModalMobileUI } from './VideoPlayer/options';
import { useVideoPlayer, useVideoPlayerPlain } from '../hooks/useVideoPlayer';

type Props = any;

const PLAYER_MOBILE_ORIGINAL_CLASS_NAME = 'player-mobile-orig';
const playerOptions = {
  ...videoPlayerOptions,
  fullscreen: {
    enabled: true,
    fallback: true,
    iosNative: true,
    container: null
  },
  autoplay: false,
  muted: false,
  // controls: []
}

const VideoModalMobile = ({
  setVideoIndex,
  videoIndex,
  projects,
}: Props): React.Node => {
  const [width, height] = useWindowSize();
  const {
    setPlayer: setMobileOriginalPlayer,
    setVideoEl,
    videoEl
  } = useMobileOriginal();
  const mobileVideoEl = React.useRef(null);
  const setMobileVideoEl = React.useCallback(node => {
    if (mobileVideoEl.current) {
      // cleanup?
    }
    if (node) {
      console.log('got mobile video node', node);
      mobileVideoEl.current = node;
      setVideoEl(node);
    }
  }, [mobileVideoEl]);

  const getSrc = () => srcTest || projects[videoIndex].origin[720];

  const { player, setPlayer } = useVideoPlayer({
    options: playerOptions,
    selector: mobileVideoEl.current,
    sources: {}
  });

  const onReady = () => {
    const p = player;
    let interval = -1;
    console.log('ready', p.media);
    if (!isIpad) {
      p.media.controls = false;
      p.media.removeAttribute('controls');
      interval = setInterval(() => {
        // console.log('removing', p.media);
        if (p && p.media) {
          p.media.controls = false;
          p.media.removeAttribute('controls');
        }
      }, 2);
    }
    try {
      const e = p.play();
      if (e) {
        e.catch((err) => {
          console.error('error', err);
          if (isIpad) {
            p.toggleControls(true);
          }
        });
      }
    } catch (error) {
      console.error(error, '0');
    }
  };

  const onPlayerReady = p => {
    console.log(p);
    setMobileOriginalPlayer(p);
  }

  return (
    <div>
      {videoIndex !== -1 && (
        <div
          className={css(styles.root)}
          style={{
            height,
            width,
            display: 'block'
          }}
        >
          <VideoFullMobile
            src={getSrc()}
            height={height}
          // onPlayerReady={onPlayerReady}
          />
          {showModalMobileUI && <>
            <TransparentButton
              onClick={() => {
                setVideoIndex(-1);
              }}
            >
              <section className={css(styles.close)}>
                <Cross />
              </section>
            </TransparentButton>
            <div className={css(styles.titleWrapper)}>
              <p className={css(styles.titleText)}>
                {projects[videoIndex] ? projects[videoIndex].Brand : ''}
                <br />
                {projects[videoIndex] ? projects[videoIndex].Title : ''}
                <br />
                {`by ${projects[videoIndex] ? projects[videoIndex].Author : ''}`}
              </p>
            </div>
          </>}
          <Footer>
            {showModalMobileUI && <Logo />}
          </Footer>
        </div>
      )}
    </div>
  );
};

export default (props: Props): React.Node => (
  <VideoControllerContext.Consumer>
    {({ videoIndex, setVideoIndex }) => (
      <>
        <VideoModalMobile
          {...{
            ...props,
            setVideoIndex,
            videoIndex,
          }}
        />
      </>
    )}
  </VideoControllerContext.Consumer>
);

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1000,
  },
  titleText: {
    color: '#FFF',
    fontFamily: 'UniversLight',
    fontSize: '20px',
    lineHeight: '27px',
    marginTop: -15,
  },
  titleWrapper: {
    left: 30,
    position: 'absolute',
    top: 100,
  },
});
