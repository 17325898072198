// @flow

import * as React from 'react';

type Props = {
  children: React.Node,
};

export const HideControlsContext = React.createContext({
  hide: false,
  setHide: () => { }
});

export const HideControls = ({ children }: Props) => {
  const [hide, setHide] = React.useState(false);

  return (
    <HideControlsContext.Provider value={{
      hide,
      setHide
    }}>
      {children}
    </HideControlsContext.Provider>
  );
};

export const useHideControls = () => React.useContext(HideControlsContext);