// @flow

import * as React from 'react';

// libs
import Fullscreen from 'react-full-screen';

type Props = {
  children: React.Node,
};

export const FullScreenContext =
  // $FlowFixMe
  React.createContext({
    fullScreen: false,
    handleFullScreen: () => {},
    resetFullScreen: () => {},
  });

const FullScreen = ({ children }: Props) => {
  const [fullScreen, setFullScreen] = React.useState(false);
  const [
    fullScreenProcessing,
    setFullScreenProcessing,
  ] = React.useState(false);

  const handleFullScreen = () => {
    setFullScreenProcessing(!fullScreenProcessing);

    setTimeout(() => {
      setFullScreen(!fullScreen);

      setTimeout(() => {
        setFullScreenProcessing(false);
      }, 300);
    }, 300);
  };

  const enterFullScreen = () => {
    setFullScreenProcessing(true);

    setTimeout(() => {
      setFullScreen(true);

      setTimeout(() => {
        setFullScreenProcessing(false);
      }, 300);
    }, 300);
  };

  const resetFullScreen = () => {
    setFullScreenProcessing(true);

    setTimeout(() => {
      setFullScreen(false);

      setTimeout(() => {
        setFullScreenProcessing(false);
      }, 300);
    }, 300);
  };

  return (
    <FullScreenContext.Provider
      value={{
        enterFullScreen,
        fullScreen,
        fullScreenProcessing,
        handleFullScreen,
        resetFullScreen,
        setFullScreenProcessing,
      }}
    >
      <Fullscreen
        enabled={fullScreen}
        onChange={(nextState) => {
          setFullScreen(nextState);
        }}
      >
        <div className="full-screenable-node">{children}</div>
      </Fullscreen>
    </FullScreenContext.Provider>
  );
};

export default FullScreen;
