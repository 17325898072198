// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Components
import { HorizontalCenter } from '.';

type Props = {
  children: React.Node,
};

const Footer = ({ children }: Props): React.Node => (
  <section className={css(styles.root)}>
    <HorizontalCenter>{children}</HorizontalCenter>
  </section>
);

const styles = StyleSheet.create({
  root: {
    bottom: 30,
    position: 'fixed',
    width: '100%',
  },
});

export default Footer;
