// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import {
  FullScreenContext,
  VideoControllerContext,
} from '../../modules';

// Components
import { Cross, TransparentButton } from '..';

type Props = {
  exitFullscreen: () => void;
}

export const VideoButtonClose: React.FC<Props> = ({
  exitFullscreen
}) => (
    <VideoControllerContext.Consumer>
      {({ videoIndex, setVideoIndex }) =>
        videoIndex !== -1 && (
          <TransparentButton
            onClick={() => {
              // exitFullscreen();
              setVideoIndex(-1);
            }}
          >
            <section className={css(styles.root)}>
              <Cross />
            </section>
          </TransparentButton>
        )
      }
    </VideoControllerContext.Consumer>
  );

const styles = StyleSheet.create({
  root: {
    position: 'fixed',
    right: 10,
    top: 10,
    zIndex: 1337,
  },
});