// @flow

import * as React from 'react';

type Props = {
  block?: boolean,
  children: any,
};

const Hover = ({ block = false, children }: Props) => {
  const [hover: boolean, setHover: Function] = React.useState(false);

  return (
    <div
      onMouseOut={() => {
        if (!block) {
          if (hover) {
            setHover(false);
          }
        }
      }}
      onMouseOver={() => {
        if (!block) {
          if (!hover) {
            setHover(true);
          }
        }
      }}
    >
      {children({ hover })}
    </div>
  );
};

export default Hover;
