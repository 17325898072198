// @flow

import * as React from 'react';

// Libs
import { StyleSheet, css } from 'aphrodite';

// Modules
import { FullScreenContext } from '../modules';

import Icon from '../assets/icons/full-screen.svg';

const size = 38;

const VideoFullScreenButton = (): React.Node => (
  <FullScreenContext.Consumer>
    {({ handleFullScreen }) => (
      <button
        className={css(styles.root)}
        onClick={() => {
          handleFullScreen();
        }}
        type="button"
      >
        <img alt="full" className={css(styles.img)} src={Icon} />
      </button>
    )}
  </FullScreenContext.Consumer>
);

const styles = StyleSheet.create({
  img: {
    '@media screen and (max-width: 414px)': {
      bottom: 15,
      height: size - 12,
      right: 10,
      width: size - 12,
    },
    '@media screen and (min-width: 2000px)': {
      height: `${size * 1.7 - 6}px`,
      width: `${size * 1.7 - 6}px`,
    },

    height: size - 6,

    width: size - 6,
  },
  root: {
    '-webkit-user-select': 'none',
    backgroundColor: 'transparent',
    border: 'none',
    bottom: 34,
    outline: 'none',
    position: 'absolute',
    right: 30,
    zIndex: 13,
  },
});

export default VideoFullScreenButton;
