// @flow

import * as React from 'react';

type Props = {
  children: React.Node,
};

export const MobileOriginalContext = React.createContext({
  videoEl: null,
  setVideoEl: () => { },
  player: null,
  setPlayer: () => { },
});

export const MobileOriginal = ({ children }: Props) => {
  const [videoEl, setVideoEl] = React.useState(false);
  const [player, setPlayer] = React.useState(false);

  return (
    <MobileOriginalContext.Provider value={{
      videoEl,
      setVideoEl,
      player,
      setPlayer
    }}>
      {children}
    </MobileOriginalContext.Provider>
  );
};

export const useMobileOriginal = () => React.useContext(MobileOriginalContext);